import { v4 as uuid } from "uuid"
import { created } from "../others_constants"
import sanitize_image_route from "../../methods/sanitize_image_route"

/**
 * @const {String} src - src of image
 * @const {String} file - File object
 * @const {String} route - route of image
 */
export default ({
  src,
  file,
  route
}) => ({
  src,
  id: uuid(),
  file,
  change_type: created,
  ...(route && { route: sanitize_image_route(route) })
})
