<template>
  <section class="user-guide-image-upload">
    <div class="user-guide-image-upload__uploader">
      <input
        data-cy="upload"
        type="file"
        id="image-upload"
        accept=".jpg, .jpeg, .png, .webp"
        multiple="multiple"
        @drop="event => add_images(event.target.files)"
        @input="event => add_images(event.target.files)"
        v-cloak
      >
      <label for="image-upload">
        <md-icon>{{ adding_product_images || !images.length ? "add" : "change_circle" }}</md-icon>
      </label>
    </div>

    <div v-if="images.length" class="user-guide-image-upload__images-wrapper" :class="{
      'user-guide-image-upload__images-wrapper--multi-image': images.length > 1,
    }">
      <div class="user-guide-image-upload__images">
        <div
          class="user-guide-image-upload__image"
          v-for="(image, index) in images"
          :key="index"
        >
          <div class="user-guide-image-upload__image__remove" @click="remove_image(index)">
            <md-icon>close</md-icon>
          </div>
          <img :src="image.src || image" />
        </div>
      </div>
    </div>
    <div v-else class="user-guide-image-upload__demo-images-wrapper">
      <div class="user-guide-image-upload__divider">
        {{ $translate("user_guide.divider") }}
      </div>
      <p class="user-guide-image-upload__title">
        {{ $translate("user_guide.select_demo_images") }}
      </p>
      <div class="user-guide-image-upload__demo-images">
        <div
          v-for="image_number in [1, 2, 3, 4]"
          :key="image_number"
          class="user-guide-image-upload__demo-image"
          @click="select_demo_image(create_demo_image(image_number))"
        >
          <img :src="create_demo_image(image_number)" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import empty_image_file_object from "../../../constants/empty_objects/empty_image_file_object"
import { created } from "../../../constants/others_constants"

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    adding_product_images: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    select_demo_image(image) {
      const image_object = { src: image, change_type: created }

      this.$emit(
        "updating",
        this.adding_product_images ?
          [image_object, ...this.images] : image_object
      )
    },
    create_demo_image(image_number) {
      return `/static/user_guide/demo_${this.adding_product_images ? 'product' : 'collection'}_image_${image_number}.jpeg`
    },
    add_images(files) {
      Array.from(files).forEach((file, index) => {
        try {
          const reader = new FileReader()

          reader.addEventListener("load", () => {
            const image = empty_image_file_object({
              src: reader.result,
              file,
              route: `${this.title}_${index}`
            })

            this.$emit(
              "updating",
              this.adding_product_images ?
                [image, ...this.images] :
                image
            )
          }, false)

          reader.readAsDataURL(file)
        } catch (error) {
          console.log(error)
        }
      })
    },
    remove_image(index) {
      this.$emit(
        "updating",
        this.adding_product_images ?
          this.images.filter((_, i) => i !== index) :
          null
      )
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-image-upload {
    $image-size: 200px;
    $image-size--small: 150px;
    $uploader-size: 80px;
    $uploader-size--small: 60px;

    display: grid;
    grid-template-columns: $uploader-size 1fr;
    grid-gap: $default-size;
    align-items: center;
    width: fit-content;
    margin: auto;

    @media (max-width: $tablet--small) {
      grid-template-columns:$uploader-size--small 1fr;
    }

    @media (max-width: $mobile--large) {
      grid-template-columns: 1fr;
      row-gap: $default-size;
      justify-items: center;
      width: 100%;
    }

    &__uploader {
      position: relative;
      width: $uploader-size;
      height: $uploader-size;
      border: 1px solid $pure-black;
      font-size: $font-size--large;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: $material-transition;
      border-radius: $border-radius;
      background-color: $pure-white;

      @media (max-width: $tablet--small) {
        width: $uploader-size--small;
        height: $uploader-size--small;
      }

      &:hover {
        background-color: $pure-black;

        .md-icon {
          color: $pure-white !important;
        }
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .md-icon {
          color: $pure-black !important;
          font-size: 30px !important;
        }
      }
    }

    &__images {
      overflow-x: scroll;
      overflow-y: hidden;
      height: $image-size;
      width: 100%;
      white-space: nowrap;
      text-align: left;

      @media (max-width: $tablet--small) {
        height: $image-size--small;
      }

      &-wrapper {
        position: relative;
        overflow: hidden;
        height: $image-size;
        width: calc(50vw - 140px);
        margin-right: calc(-50vw);

        @media (max-width: $tablet--small) {
          width: 100%;
          margin-right: 0;
          height: $image-size--small;
        }

        &--multi-image {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            background: linear-gradient(to right, transparent, $pure-white);
          }
        }
      }
    }

    &__image {
      width: $image-size;
      height: $image-size;
      position: relative;
      border: 1px solid $pure-black;
      border-radius: $border-radius;
      overflow: hidden;
      display:inline-block;
      margin-right: $default-size;


      @media (max-width: $tablet--small) {
        width: $image-size--small;
        height: $image-size--small;
      }
      
      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }

      &__remove {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        transition: $material-transition;

        .md-icon {
          color: $pure-white !important;
          font-size: 30px !important;
          filter: drop-shadow(0 0 0.5rem $pure-black);
        }

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &__demo-image {
      position: relative;
      width: $image-size;
      height: $image-size;
      border: 1px solid $pure-black;
      border-radius: $border-radius;
      overflow: hidden;
      cursor: pointer;

      @media (max-width: $tablet--small) {
        width: $image-size--small;
        height: $image-size--small;
      }

      @media (max-width: $mobile--large) {
        width: 100%;
        height: auto;

        &:after {
          display: block;
          content: "";
          padding-bottom: 100%;
        }
      }
      
      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;

        @media (max-width: $mobile--large) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &s {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: $default-size;
        grid-area: images;

        &-wrapper {
          display: grid;
          column-gap: $default-size;
          grid-template-areas:
            "divider title title"
            "divider images images"
            "divider images images";
          ;

          @media (max-width: $tablet--small) {
            padding-right: $default-size;
          }

          @media (max-width: $mobile--large) {
            column-gap: $half-default-size;
            grid-template-areas:
              "divider divider"
              "title title"
              "images images"
              "images images"
            ;
            padding-right: 0;
            width: 100%;
          }
        }
      }
    }

    &__title {
      font-size: $font-size--medium-large;
      margin: 0 0 $half-default-size;
      text-align: center;
      grid-area: title;
    }

    &__divider {
      grid-area: divider;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size--large;
      padding: 0 $double-default-size;
      font-weight: bold;

      @media (max-width: $tablet--small) {
        padding: 0 $default-size;
      }

      @media (max-width: $mobile--large) {
        margin: $default-size auto;
      }
    }
  }
</style>
